import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import ContactForm from "../components/ContactForm";

class ContactPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="index-container">
          <Helmet title={config.siteTitle} />
          <SEO />
          <h1>Get in touch</h1>
          <p>You can send us mail at:</p>
          <p>Holobiont Lab<br/>
          PO Box 23462 <br/>
          Philadelphia PA 19143<br/>
          usa</p>
          <p>or via this form:</p>
          <ContactForm />
        </div>
      </Layout>
    );
  }
}

export default ContactPage;
