import React from "react"
import "./contact.scss"

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSubmit = e => {

    // check if honeypot fields are filled out
    if (typeof this.state.message == 'undefined' && typeof this.state.subject == 'undefined' && typeof this.state.xnoms !== 'undefined' && this.state.xnoms.length > 0 ) {

      if (typeof this.state.email !== "undefined") {
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(this.state.email)) {
          this.setState({'emailError' : <div className="formerror">That doesn't look like no email.</div>})
          return
        }
      }

      const fields = {"fields": {"Name": this.state.name, "Subject": this.state.xnosub, "Email": this.state.email, "Message": this.state.xnoms, "Status": "incoming"}}
      fetch("https://api.airtable.com/v0/appwGGlcj74mMoPpq/Table%201", {
      method: "POST",
      headers: {
      "Authorization": `Bearer ${process.env.AIRTABLE_API_KEY}`,
      "Content-Type": "application/json"},
      body: JSON.stringify(fields)

    })
    .then((result) => {
      document.getElementsByClassName('contact-form')[0].innerHTML = "<h1>THANKS!</h1><h2>We sent your message!!!</h2>"
    })
    .catch(error => console.log(error))

    e.preventDefault();
  } else {
    console.log("else");
    if (typeof this.state.email === 'undefined' ) {
      this.setState({'emailError' : <div className="formerror">How can we reply if you leave us no email?</div>}) 
    } else {
      this.setState({'emailError' : ''}) 
    }
    if (typeof this.state.xnoms === 'undefined' || this.state.xnoms.length < 2) {
      this.setState({'messageError' : <div className="formerror">There must be a message to send.</div>}) 
    }

    e.preventDefault();
  }
}

handleChange = e => this.setState({ [e.target.name]: e.target.value})


render() {
  
  return (
    <form className="contact-form">
      <div>
        <input type="text" name="name" onChange={this.handleChange} size="50" placeholder="Your Name" />
      </div>
      <div>
        <input type="text" name="subject" onChange={this.handleChange} size="50" placeholder="Subject" />
        <input type="text" name="xnosub" onChange={this.handleChange} size="50" placeholder="Subject" />
      </div>
      <div>
      { this.state.emailError }
        <input type="email" name="email" onChange={this.handleChange} size="50" placeholder="Email" />
      </div>
      <div>
        { this.state.messageError }
        <textarea rows="5" cols="60" name="message" placeholder="Message here please" autoComplete="off" />
        <textarea rows="5" cols="60" name="xnoms" placeholder="Message here please" onChange={this.handleChange} />
      </div>
      <button type="submit" onClick={this.handleSubmit}>🖅 SEND 🖅</button>
    </form> 
    )
  }
}

export default ContactForm